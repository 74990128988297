import { Component, HostListener, ViewChild } from '@angular/core';

import {
	AccountService,
	AuthService,
	BaseComponent,
	LayoutService,
	PasswordSettingsModel,
	SettingsService
} from '@nstep-common/core';

import { ModalComponent } from '@nstep-common/semantic-ui';
import { forkJoin, tap } from 'rxjs';

@Component({
	selector: 'app-base-layout',
	templateUrl: './base-layout.component.html'
})
export class BaseLayoutComponent extends BaseComponent {

	@ViewChild('changePasswordModal') changePasswordModal!: ModalComponent;
	@ViewChild('twoFactorModal') twoFactorModal!: ModalComponent;

	@ViewChild('confirmTwoFactorModal') confirmTwoFactorModal!: ModalComponent;

	protected isMobile = true;
	protected isTablet = true;
	protected isSmallMonitor = true;
	protected isRegularMonitor = true;
	protected isLargeMonitor = true;

	protected currentUser: string | null = null;


	passwordSettings: PasswordSettingsModel | null = null;

	validations = {};
	isValid = false;
	askFor2F = false;
	waitingForServer = false;

	errors: string[] = [];

	constructor(private layoutService: LayoutService,
		private authService: AuthService,
		private settingsService: SettingsService) {
		super();

		this.currentUser = this.authService.JWT != null ? this.authService.JWT.name : null;

		this.initSettings();
	}

	@HostListener('window:load', ['$event'])
	@HostListener('window:resize', ['$event'])
	onResize(event: Event) {
		const width = (event.currentTarget as Window).innerWidth;

		this.isMobile = width < 768;
		this.isTablet = width >= 768 && width <= 991;
		this.isSmallMonitor = width >= 992 && width <= 1920;
		this.isRegularMonitor = width > 1920 && width <= 2560;
		this.isLargeMonitor = width > 2560;

		this.layoutService.layoutHasChanged.next({
			isMobile: this.isMobile,
			isTablet: this.isTablet,
			isSmallMonitor: this.isSmallMonitor,
			isRegularMonitor: this.isRegularMonitor,
			isLargeMonitor: this.isLargeMonitor
		});
	}

	initSettings(): void {
		this.validations = {};
		this.isValid = false;
		this.askFor2F = false;

		this.errors = [];

		const getPasswordSettings = this.settingsService.getPasswordSettings()
			.pipe(tap(settings => {
				this.passwordSettings = settings;
			}));

		this.subscriptions.push(forkJoin([getPasswordSettings]).subscribe());
	}

	logout(): void {
		this.authService.logOut();
	}
}
