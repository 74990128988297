import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { valueHasChanged } from '@nstep-common/utils';
import { merge } from 'lodash';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'sm-calendar',
	template: '<ng-content></ng-content>'
})
export class CalendarComponent implements AfterViewInit, OnDestroy, OnChanges {
	jQueryElement: any;
	@Input() settings: any = {};

	@Input() value: Date | null = null;
	@Output() valueChange = new EventEmitter<Date | null>();

	@Input() minValue: Date | null = null;
	@Input() maxValue: Date | null = null;

	minDate: Date | null = null;

	constructor(private elementRef: ElementRef) {
	}

	ngAfterViewInit(): void {
		if (this.jQueryElement) {
			return;
		}

		const settings = merge({
			onShow: () => {
				if (this.minDate && !this.jQueryElement.calendar('setting', 'minDate')) {
					this.jQueryElement
						.calendar('setting', 'minDate', this.minDate)
						.calendar('refresh');
				}
			},
			onChange: (date: Date | null) => {
				this.value = date;
				this.valueChange.emit(date);
			},
			onHidden: () => {
				if (!this.minValue) {
					this.jQueryElement
						.calendar('setting', 'minDate', null)
						.calendar('refresh');
				}
			}
		}, this.settings);

		this.minDate = settings.minDate;
		delete settings.minDate;

		setTimeout(() => {
			this.jQueryElement = $(this.elementRef.nativeElement).calendar(settings);
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.jQueryElement) {
			this.ngAfterViewInit();
		} else {
			if (valueHasChanged(changes['minValue'])) {
				setTimeout(() => this.jQueryElement.calendar('set minDate', this.minValue && this.minDate && new Date(this.minValue.toDateString()) < new Date(this.minDate.toDateString()) ? this.minDate : this.minValue));
			}

			if (valueHasChanged(changes['maxValue'])) {
				setTimeout(() => this.jQueryElement.calendar('set maxDate', this.maxValue && this.minDate && new Date(this.maxValue.toDateString()) < new Date(this.minDate.toDateString()) ? this.minDate : this.maxValue));
			}

			if (valueHasChanged(changes['value'])) {
				setTimeout(() => this.jQueryElement.calendar('set date', this.value, true, false));
			}
		}
	}

	ngOnDestroy(): void {
		this.jQueryElement?.calendar('destroy');
	}
}