import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationModel, JwtTokenModel, AccountService, LogService, ResetPasswordModel, AccountModel } from '@nstep-common/core';
import { AppSource, toast } from '@nstep-common/utils';
import { environment } from '@nstep-internal/environments/environment';
import { difference } from 'lodash';
import { AuthHelperService } from 'projects/common/core/services/auth/auth-helper.service';
import { EMPTY, Observable, catchError, map, tap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private timeout: any;
	private jwt: JwtTokenModel | null = null;

	get JWT(): JwtTokenModel | null {
		if (this.authHelperService.isAccessTokenExpired()) {
			return null;
		}

		return this.jwt;
	}

	constructor(private logger: LogService,
		private accountService: AccountService,
		private authHelperService: AuthHelperService,
		private router: Router) {
		this.tryLogIn();
	}

	private startAutoLogout(): void {
		const expirationDate = new Date(this.jwt!.exp * 1000);
		const dateNow = new Date();

		const milisecondsLeft = Math.max(0, expirationDate.getTime() - dateNow.getTime() - 1000);

		this.timeout = setTimeout(() => this.refreshSession().subscribe(), milisecondsLeft);
	}

	tryLogIn(): void {
		this.jwt = this.authHelperService.decodeJwt(this.authHelperService.getAccessToken());

		if (this.jwt) {
			this.startAutoLogout();
		}
	}

	logIn(model: AccountModel): JwtTokenModel | null {
		if (!model.accessToken || !model.refreshToken) {
			return null;
		}

		this.authHelperService.storeSecurityTokens(model.refreshToken, model.accessToken);
		this.jwt = this.authHelperService.decodeJwt(model.accessToken);

		this.startAutoLogout();

		this.logger.log(`User ${this.jwt!.nameIdentifier} successfully logged in.`);

		return this.jwt;
	}
	
	logOut(): void {
		let redirectUrl = 'login';

		if (this.jwt != null) {
			if (environment.appName == AppSource.Public && this.jwt.role != 'User') {
				redirectUrl += '/local';
			}

			this.accountService.logOut();

			this.jwt = null;
		}

		this.authHelperService.clearSecurityTokens();
		clearTimeout(this.timeout);

		this.router.navigate([redirectUrl]);
	}

	hasAccess(...permisisons: string[]): boolean {
		if (!permisisons.length) {
			return true;
		}

		const access = this.jwt ? this.jwt.access : [];
		return difference(permisisons, access).length == 0;
	}

	hasPartialAccess(...permisisons: string[]): boolean {
		if (!permisisons.length) {
			return true;
		}

		const access = this.jwt ? this.jwt.access : [];
		const diff = difference(permisisons, access).length;

		return diff < permisisons.length;
	}

	refreshSession(): Observable<any> {
		return this.accountService
			.refresh({
				accessToken: this.authHelperService.getAccessToken()!,
				refreshToken: this.authHelperService.getRefreshToken()
			})
			.pipe(
				tap(r => {
					if (!r.accessToken || !r.refreshToken) {
						throw ['Security tokens unavailable'];
					}

					this.authHelperService.storeSecurityTokens(r.refreshToken, r.accessToken);
					this.jwt = this.authHelperService.decodeJwt(r.accessToken);

					this.startAutoLogout();

					this.logger.log(`User access for ${this.jwt!.nameIdentifier} has been refreshed.`);
				}),
				catchError(() => {
					this.logOut();
					toast('', 'Your session has expired.', 'orange');

					return EMPTY;
				})
			);
	}
}
