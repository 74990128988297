import { AppSource, Enviroment } from '@nstep-common/utils';

export const environment: Enviroment = {
	target: 'local',
	disableConsoleLogs: false,
	logRequests: true,
	expandedLogRequests: false,
	angularRouterBaseHref: '/',
	appName: AppSource.Internal,
	apiUrl: 'https://localhost:7292/api', // use relative or full url
	metricsCallInterval: 10000
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
