<div class="ui stackable inverted blue borderless menu"
	style="border-radius: 0; margin-bottom: 35px;">
	<div class="ui fluid container"
		[style.padding-left]="!isLargeMonitor ? (isRegularMonitor ? '100px' : '20px') : '200px'"
		[style.padding-right]="!isLargeMonitor ? (isRegularMonitor ? '100px' : '20px') : '200px'">
		<div class="item">
			NATO System for Tracking Entitlements & Privileges

			<ng-content select="[title]"></ng-content>
		</div>

		<div class="ui simple dropdown right floated item">
			<i class="user outline icon"></i>{{currentUser}} <i class="dropdown icon"></i>
			<div class="menu">
				<button class="item"
					(click)="logout()">
					<i class="door open icon"></i>Log Out
				</button>
			</div>
		</div>
	</div>
</div>

<div class="ui fluid container"
	[style.padding-left]="!isLargeMonitor ? (isRegularMonitor ? '100px' : '20px') : '200px'"
	[style.padding-right]="!isLargeMonitor ? (isRegularMonitor ? '100px' : '20px') : '200px'"
	style="padding-bottom: 50px;">

	<div class="ui two column stackable padded grid"
		style="box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);">
		<div class="left attached stretched column"
			style="width: 250px; padding: 0">
			<div class="ui left attached horizontally fitted segment">
				<img class="ui small image"
					src="assets/images/logo.png"
					style="width: 130px; display: block; margin: 10px auto" />

				<ng-content select="[menu]"></ng-content>

				<br />
			</div>
		</div>

		<div class="right attached stretched column"
			style="width: calc(100% - 250px); padding: 0">
			<div class="ui right attached padded segment">
				<ng-content select="[content]"></ng-content>
			</div>
		</div>
	</div>
</div>
