import { Component } from '@angular/core';
import { ApiService, BaseComponent } from '@nstep-common/core';
import { Enviroment } from '@nstep-common/utils';
import { AuthHelperService } from 'projects/common/core/services/auth/auth-helper.service';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent extends BaseComponent {

	constructor(private apiService: ApiService,
		private authHelperService: AuthHelperService,
		private environment: Enviroment) {
		super();

		this.registerPerformanceObserver(this.apiService, this.authHelperService, this.environment);
	}
}
