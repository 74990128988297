import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, BaseComponent, SettingsService } from '@nstep-common/core';
import { AppPermissions, routePermissions } from '@nstep-public/shared';

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrl: './layout.component.css'
})
export class LayoutComponent extends BaseComponent implements OnInit {
	protected permissions = AppPermissions;
	serverSettings: any = {};

	constructor(private authService: AuthService,
		private serverSettingsService: SettingsService,
		private router: Router) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		if (this.authService.JWT) {
			this.subscriptions.push(this.serverSettingsService.getServerSettings()
				.subscribe(settings => this.serverSettings = settings));

			const currentRoute = this.router.url.substring(1);
			if (currentRoute && this.authService.hasAccess(...routePermissions[currentRoute])) {
				return;
			}

			for (const route of Object.keys(routePermissions)) {
				const permissions = routePermissions[route];


				if (this.authService.hasAccess(...permissions)) {
					this.router.navigate([`/${route}`]);
					return;
				}
			}
		}
	}
}