import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownOption } from '@nstep-common/semantic-ui';

@Component({
	selector: 'app-month-selector',
	template: `
	<sm-dropdown class="ui selection dropdown"
		[options]="options"
		[(value)]="value"
		(valueChange)="valueChange.emit($event)"
		[class.disabled]="isDisabled"
		style="width: 100%">
		<div class="text"></div>
		<i class="dropdown icon"></i>
	</sm-dropdown>
	`
})
export class MonthSelectorComponent {
	@Input() isDisabled = false;
	@Input() isRelative = false;

	@Input() value = 1;
	@Output() valueChange = new EventEmitter<number>();

	options: DropdownOption[] = [
		new DropdownOption({
			value: 1,
			name: "January"
		}),
		new DropdownOption({
			value: 2,
			name: "February"
		}),
		new DropdownOption({
			value: 3,
			name: "March"
		}),
		new DropdownOption({
			value: 4,
			name: "April"
		}),
		new DropdownOption({
			value: 5,
			name: "May"
		}),
		new DropdownOption({
			value: 6,
			name: "June"
		}),
		new DropdownOption({
			value: 7,
			name: "July"
		}),
		new DropdownOption({
			value: 8,
			name: "August"
		}),
		new DropdownOption({
			value: 9,
			name: "September"
		}),
		new DropdownOption({
			value: 10,
			name: "October"
		}),
		new DropdownOption({
			value: 11,
			name: "November"
		}),
		new DropdownOption({
			value: 12,
			name: "December"
		})
	];
}
