import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountService, AuthenticatorStatus, AuthService, BaseComponent } from "@nstep-common/core";

@Component({
	selector: 'app-two-factor',
	templateUrl: './two-factor.component.html'
})
export class TwoFactorComponent extends BaseComponent {
	@Input() model: AuthenticatorStatus = new AuthenticatorStatus();

	isAuthenticated = false;
	askForCode = false;

	@Input() code: string | null = null;
	@Output() codeChange = new EventEmitter<string | null>();
	
	@Output() onContinueClicked = new EventEmitter<string>();
	@Output() onEnableClicked = new EventEmitter();

	validations = {};
	errors: string[] = [];

	constructor(
		private accountService: AccountService,
		private authService: AuthService
	) {
		super();

		this.isAuthenticated = this.authService.JWT != null;
	}
	
	resetAuthenticator(): void {
		this.subscriptions.push(this.accountService.resetMobileAuthenticator()
			.subscribe(r => {
				this.model.mobile = r;
			}));
	}

	continue(): void {
		this.code = null;
		this.askForCode = true;

		if (!this.model.emailEnabled) {
			return;
		}

		this.onContinueClicked.emit(this.model.email!);
	}

	enableAuthenticator(): void {
		if (!this.code) {
			return;
		}

		this.codeChange.emit(this.code);
		this.onEnableClicked.emit();
	}
}