
import moment, { Moment } from "moment";

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { LogService, PagedQueryParameter, PagedResponse, PaginationModel, UpdateDateHttpInterceptor } from "@nstep-common/core";
import { expandedLog, toastHttpError, toastGenericError, Enviroment, Cache, JsonMapper, toastRequestDenied, Any } from "@nstep-common/utils";
import { Observable, catchError, delay, map, shareReplay, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { isArray } from "lodash";

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	protected apiUrl: string;
	private cacheMap: { [key: string]: Cache<any> } = {};

	constructor(private httpClient: HttpClient,
		private environment: Enviroment,
		private logger: LogService) {

		let url = this.environment.apiUrl;

		if (!url.startsWith('http')) {
			const hostUrl = window.location.protocol + '//' + window.location.host;
			url = `${hostUrl}${this.environment.apiUrl}`;
		}

		this.apiUrl = url;
		this.environment = this.environment;
	}

	protected getCaller(index: number): string {
		const err = new Error();
		const frames = err.stack ? err.stack.split('\n') : [];

		for (let i = index; i > 0; i--) {
			const caller = frames[i].trim().split(' ')[1];

			if (!caller.startsWith('http')) {
				return caller;
			}
		}

		return 'N/A';
	}

	protected handleItem<T>(type: { new(): T }, result: any): T {
		const jsonMapper = new JsonMapper();
		const deserializeResult = jsonMapper.deserializeObject(result, type);

		if (deserializeResult.hasErrors()) {
			this.logger.error("Bad item format received from server.", deserializeResult.errors);
		}

		return deserializeResult.value;
	}

	protected handleArray<T>(type: [{ new(): T }], result: any): T[] {
		const jsonMapper = new JsonMapper();
		const deserializeResult = jsonMapper.deserializeArray(result, type[0]);

		if (deserializeResult.hasErrors()) {
			this.logger.error("Bad array format received from server.", deserializeResult.errors);
		}

		return deserializeResult.values;
	}

	protected handleError(url: string, params: any, resp: any, caller?: string): Observable<never> {
		this.logRequest(url, params, resp, false, undefined, caller);

		if (resp?.error?.errors) {
			return throwError(() => resp.error.errors);
		}
		else if (resp?.error?.detail) {
			toastRequestDenied(resp.error.detail);

			return throwError(() => []);
		}
		else if (resp instanceof HttpErrorResponse) {
			this.logger.error('Request to server has failed.', resp);
			toastHttpError(resp);

			return throwError(() => []);
		}
		else {
			toastGenericError();

			return throwError(() => []);
		}
	}

	private logRequest(target: string, params: any, response: any, isSuccess: boolean, startedAt?: Moment, caller?: string) {
		if (!this.environment.logRequests) {
			return;
		}

		const duration = startedAt ? moment.duration(moment.utc().diff(startedAt)).asSeconds() : null;

		console.groupCollapsed(`REQUEST ${duration ? `(${duration}s)` : ''}%c ${isSuccess ? 'SUCCESS' : 'FAIL'}%c ${target} %c${caller ? `\nfrom ${caller}` : ''}`,
			`color: ${isSuccess ? 'limegreen' : 'red'}`,
			'font-weight: normal; color: orange',
			'font-weight: normal; color: grey');

		const formattedParams = {
			...params
		};

		UpdateDateHttpInterceptor.formatDates(formattedParams);

		if (this.environment.expandedLogRequests) {
			expandedLog(formattedParams, 'PARAMS');
			expandedLog(response, 'RESPONSE');
		}
		else {
			console.info('PARAMS', this.removePrototype(formattedParams));
			console.info('RESPONSE', this.removePrototype(response));
		}

		console.groupEnd();
	}

	private removePrototype(arg: any) {
		if (!arg) {
			return arg;
		}

		if (typeof arg === 'object' && !arg.length) {
			const tempObj = JSON.parse(JSON.stringify(arg));
			tempObj.__proto__ = null;

			return tempObj;
		}

		return arg;
	}

	private getHttpObservable<T>(method: <T>(...args: any) => Observable<T>, type: { new(): T } | [{ new(): T }], url: string, body: any | null, options: any): Observable<T> | Observable<T[]> {
		const startedAt = moment.utc();
		const caller = this.getCaller(4);

		const fullUrl = `${method.name.toUpperCase()}:${url}`;

		return isArray(type)
			? method.apply(this.httpClient, [url, body ?? options, options])
				.pipe(
					map(res => {
						this.logRequest(fullUrl, body ?? options, res, true, startedAt, caller);
						return this.handleArray(type, res);
					}),
					catchError(err => this.handleError(fullUrl, body ?? options, err, caller))
				)
			: method.apply(this.httpClient, [url, body ?? options, options])
				.pipe(
					map(res => {
						this.logRequest(fullUrl, body ?? options, res, true, startedAt, caller);
						return this.handleItem(type, res);
					}),
					catchError(err => this.handleError(fullUrl, body ?? options, err, caller))
				)
	}

	private getCachedObservable<T>(relativeUrl: string, options: any, observable: Observable<T> | Observable<T[]>): Observable<T> | Observable<T[]> {
		const cache = this.cacheMap[relativeUrl] = this.cacheMap[relativeUrl] ?? new Cache<T>();

		let cachedObservable = cache.getValue(options);

		if (!cachedObservable) {
			cachedObservable = (observable as Observable<any>).pipe(delay(100), shareReplay(1));
			cache.setValue(cachedObservable, options);
		}

		return cachedObservable;
	}

	get<T>(type: { new(): T }, relativeUrl: string, options?: any): Observable<T>;
	get<T>(type: [{ new(): T }], relativeUrl: string, options?: any): Observable<T[]>;
	get<T>(type: { new(): T } | [{ new(): T }], relativeUrl: string, options?: any): Observable<T> | Observable<T[]> {
		const httpMethod = this.httpClient.get<T>;
		const observable = this.getHttpObservable<T>(httpMethod, type, `${this.apiUrl}/${relativeUrl}`, null, options);
		const cachedObservable = this.getCachedObservable(relativeUrl, options, observable);

		return cachedObservable;
	}

	getPage<TModel>(modelType: [{ new(): TModel }], relativeUrl: string, model: PagedQueryParameter): Observable<PagedResponse<TModel>> {
		return this.get(Any, relativeUrl, { observe: 'response', params: model })
			.pipe(map((res: any) => {
				const page = this.handleItem(PaginationModel, JSON.parse(res.headers.get('X-Pagination')));
				const model = this.handleArray(modelType, res.body);

				return new PagedResponse<TModel>({
					page: page,
					results: model
				});
			}));
	}

	post<T>(type: { new(): T }, relativeUrl: string, body?: any, options?: any): Observable<T>;
	post<T>(type: [{ new(): T }], relativeUrl: string, body?: any, options?: any): Observable<T[]>;
	post<T>(type: { new(): T } | [{ new(): T }], relativeUrl: string, body?: any, options?: any): Observable<T> | Observable<T[]> {
		const httpMethod = this.httpClient.post<T>;
		const observable = this.getHttpObservable<T>(httpMethod, type, `${this.apiUrl}/${relativeUrl}`, body, options);

		return observable;
	}

	postPage<TModel>(modelType: [{ new(): TModel }], relativeUrl: string, model: PagedQueryParameter): Observable<PagedResponse<TModel>> {
		return this.post(Any, relativeUrl, model, { observe: 'response' })
			.pipe(map((res: any) => {
				const page = this.handleItem(PaginationModel, JSON.parse(res.headers.get('X-Pagination')));
				const model = this.handleArray(modelType, res.body);

				return new PagedResponse<TModel>({
					page: page,
					results: model
				});
			}));
	}

	postNoContent(relativeUrl: string, body?: any, options?: any): Observable<any> {
		const httpMehtod = this.httpClient.post<any>;
		const observable = this.getHttpObservable<any>(httpMehtod, Any, `${this.apiUrl}/${relativeUrl}`, body, options);

		return observable;
	}

	put<T>(type: { new(): T }, relativeUrl: string, body?: any, options?: any): Observable<T>;
	put<T>(type: [{ new(): T }], relativeUrl: string, body?: any, options?: any): Observable<T[]>;
	put<T>(type: { new(): T } | [{ new(): T }], relativeUrl: string, body?: any, options?: any): Observable<T> | Observable<T[]> {
		const httpMethod = this.httpClient.put<T>;
		const observable = this.getHttpObservable<T>(httpMethod, type, `${this.apiUrl}/${relativeUrl}`, body, options);

		return observable;
	}

	putNoContent(relativeUrl: string, body?: any, options?: any): Observable<any> {
		const httpMethod = this.httpClient.put<any>;
		const observable = this.getHttpObservable<any>(httpMethod, Any, `${this.apiUrl}/${relativeUrl}`, body, options);

		return observable;
	}

	patch<T>(type: { new(): T }, relativeUrl: string, body?: any, options?: any): Observable<T>;
	patch<T>(type: [{ new(): T }], relativeUrl: string, body?: any, options?: any): Observable<T[]>;
	patch<T>(type: { new(): T } | [{ new(): T }], relativeUrl: string, body?: any, options?: any): Observable<T> | Observable<T[]> {
		const httpMethod = this.httpClient.patch<T>;
		const observable = this.getHttpObservable<T>(httpMethod, type, `${this.apiUrl}/${relativeUrl}`, body, options);

		return observable;
	}

	patchNoContent(relativeUrl: string, body?: any, options?: any): Observable<any> {
		const httpMethod = this.httpClient.patch<any>;
		const observable = this.getHttpObservable<any>(httpMethod, Any, `${this.apiUrl}/${relativeUrl}`, body, options);

		return observable;
	}

	delete<T>(type: { new(): T }, relativeUrl: string, body?: any, options?: any): Observable<T>;
	delete<T>(type: [{ new(): T }], relativeUrl: string, body?: any, options?: any): Observable<T[]>;
	delete<T>(type: { new(): T } | [{ new(): T }], relativeUrl: string, options?: any): Observable<T> | Observable<T[]> {
		const httpMethod = this.httpClient.delete<T>;
		const observable = this.getHttpObservable<T>(httpMethod, type, `${this.apiUrl}/${relativeUrl}`, null, options);

		return observable;
	}

	deleteNoContent(relativeUrl: string, options?: any): Observable<any> {
		const httpMethod = this.httpClient.delete<any>;
		const observable = this.getHttpObservable<any>(httpMethod, Any, `${this.apiUrl}/${relativeUrl}`, null, options);

		return observable;
	}

	invalidateCache(relativeUrl: string) {
		if (!this.cacheMap[relativeUrl]) {
			return;
		}

		this.cacheMap[relativeUrl].clear();
	}

	invalidatePartialMatchCache(partialUrl: string) {
		if (!Object.keys(this.cacheMap).some(k => k.startsWith(partialUrl))) {
			return;
		}

		Object.keys(this.cacheMap)
			.filter(k => k.startsWith(partialUrl))
			.forEach(key => {
				this.cacheMap[key].clear();
			});
	}
}