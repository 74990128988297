import { Injectable } from '@angular/core';

import {
	AccountModel,
	ApiService,
	AuthenticationModel,
	MobileAuthenticatorModel,
	RefreshTokensModel,
	AuthenticatorStatus,
	ResetPasswordModel,
	EnableAuthenticatorModel
} from "@nstep-common/core";

import { Observable, tap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AccountService {
	constructor(private apiService: ApiService) { }

	logIn(model: AuthenticationModel): Observable<AccountModel> {
		return this.apiService.post(AccountModel, 'account/log-in', model);
	}

	refresh(model: RefreshTokensModel): Observable<RefreshTokensModel> {
		return this.apiService.post(RefreshTokensModel, 'account/refresh', model);
	}

	logOut(): Observable<any> {
		return this.apiService.postNoContent('account/log-out');
	}

	passwordReset(model: ResetPasswordModel): Observable<AccountModel> {
		return this.apiService.post(AccountModel, 'account/password/login/reset', model);
	}

	getTwoFactor(): Observable<AuthenticatorStatus> {
		return this.apiService.get(AuthenticatorStatus, 'account/2f');
	}

	loginEnableMobileAuthenticator(model: AuthenticationModel): Observable<AccountModel> {		
		return this.apiService.post(AccountModel, 'account/2f/mobile/login/enable', model)
			.pipe(tap(() => {
				this.invalidateTwoFactorCache();
			}));
	}

	enableMobileAuthenticator(model: EnableAuthenticatorModel): Observable<any> {
		return this.apiService.postNoContent('account/2f/mobile/enable', model)
			.pipe(tap(() => {
				this.invalidateTwoFactorCache();
			}));
	}

	resetMobileAuthenticator(): Observable<MobileAuthenticatorModel> {
		return this.apiService.post(MobileAuthenticatorModel, 'account/2f/mobile/reset')
			.pipe(tap(() => {
				this.invalidateTwoFactorCache();
			}));
	}

	sendEmailAuthenticatorCode(model: AuthenticationModel, email: string): Observable<any> {
		const data = {
			...model,
			email
		};

		return this.apiService.postNoContent('account/2f/email/login/send', data);
	}

	loginEnableEmailAuthenticator(model: AuthenticationModel, email: string): Observable<AccountModel> {
		const data = {
			...model,
			email
		};

		return this.apiService.post(AccountModel, 'account/2f/email/login/enable', data)
			.pipe(tap(() => {
				this.invalidateTwoFactorCache();
			}));
	}

	enableEmailAuthenticator(email: string): Observable<any> {
		return this.apiService.postNoContent('account/2f/email/enable', { userEmail: email })
			.pipe(tap(() => {
				this.invalidateTwoFactorCache();
			}));
	}

	invalidateTwoFactorCache() {
		this.apiService.invalidateCache('account/2f');
	}
}
