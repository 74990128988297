<div class="ui centered grid">
	<div class="center aligned column"
		style="margin-top: 12%;"
		[ngStyle]="{'width': showTwoFactor ? '900px' : '400px'}">

		<div *ngIf="loading"
			class="ui basic segment"
			style="width: 100%; height: 250px;">
			<div class="ui active inverted dimmer">
				<div class="ui medium text blue slow double loader">Loading</div>
			</div>
		</div>

		<div *ngIf="!loading" class="ui segment">
			<img src="assets/images/NSTEP-logo.png"
				class="image"
				style="margin-top: 20px; margin-bottom: 40px;" />

			<form class="ui large form" (submit)="onSubmit()">
				<div class="ui segment">
					@if (!requiresPasswordReset) {
					@if (requiresTwoFactorActivation) {
					@if (!showTwoFactor) {
					Due to security concerns, you should consider <br />enabling a two-factor authenticator.

					<br /><br />
					<button class="ui primary tertiary button"
						style="padding: 0 !important;"
						(click)="showTwoFactor = true">
						Click here
					</button> if you want to enable it now.

					<br /><br />
					You can also skip this for now, but keep in mind that <br /> <strong>after <span class="ui medium red text"> {{requiresTwoFactorActivationDays}} days</span>, it will be mandatory</strong>.

					<button class="ui fluid large green button"
						[class.disabled]="loginInvalid"
						(click)="logIn()">
						Continue
					</button>
					}
					@else {
					<app-two-factor #twoFactor
						[model]="account?.authenticatorStatus!"
						[(code)]="loginModel.twoFactorCode"
						(onEnableClicked)="onSubmit()"
						(onContinueClicked)="sendEmailCode($event)" />
					}
					}
					@else if (!requiresTwoFactorCode) {
					<div class="field" app-field [error]="loginValidation | path: 'userName'">
						<div class="ui left icon input">
							<i class="user icon"></i>
							<input type="text"
								autoComplete="off"
								name="userName"
								[placeholder]="userNamePlaceHolder"
								[maxlength]="userNameMaxLength"
								[(ngModel)]="loginModel.userName">
						</div>
					</div>

					<div class="field" app-field [error]="loginValidation | path: 'password'">
						<div class="ui left icon input">
							<i class="lock icon"></i>
							<input type="text"
								style="-text-security:disc; -webkit-text-security:disc;"
								autoComplete="off"
								name="password"
								[placeholder]="passwordPlaceHolder"
								[maxlength]="passwordMaxLength"
								[(ngModel)]="loginModel.password">
						</div>
					</div>

					<button class="ui fluid large blue button"
						[class.disabled]="loginInvalid"
						type="submit">
						Login
					</button>
					}
					@else {
					<div class="field" app-field [error]="loginValidation | path: 'twoFactorCode'">
						<label>
							Please enter code from authenticator:<br />
						</label>
						<div class="ui left icon input">
							<i class="shield alternate icon"></i>
							<input type="text"
								autoComplete="off"
								name="twoFactorCode"
								[placeholder]="'xxxxxx'"
								[maxlength]="6"
								[(ngModel)]="loginModel.twoFactorCode">
						</div>
					</div>

					<button class="ui fluid large blue button"
						[class.disabled]="loginInvalid"
						type="submit">
						Login
					</button>
					}
					}

					@if (requiresPasswordReset) {
					@if (!requiresTwoFactorCode) {
					<p style="text-align: left;">
						Due to security concerns you need to change your current password
					</p>

					<div class="field">
						<div class="field" app-field [error]="resetValidation | path: 'newPassword'">
							<div class="ui left icon input">
								<i class="lock icon"></i>
								<input type="text"
									style="-text-security:disc; -webkit-text-security:disc;"
									autoComplete="off"
									name="newPassword"
									[placeholder]="'New Password'"
									[(ngModel)]="resetModel.newPassword">
							</div>
						</div>
					</div>

					<div class="field">
						<div class="field" app-field [error]="resetValidation | path: 'confirmPassword'">
							<div class="ui left icon input">
								<i class="lock icon"></i>
								<input type="text"
									style="-text-security:disc; -webkit-text-security:disc;"
									autoComplete="off"
									name="confirmPassword"
									[placeholder]="'Confirm Password'"
									[(ngModel)]="resetModel.confirmPassword">
							</div>
						</div>
					</div>

					<button class="ui fluid large blue button"
						[class.disabled]="resetInvalid"
						type="submit">
						Change Password
					</button>
					}
					@else {
					<div class="field" app-field [error]="resetValidation | path: 'twoFactorCode'">
						<label>
							Please enter code from authenticator:<br />
						</label>
						<div class="ui left icon input">
							<i class="shield alternate icon"></i>
							<input type="text"
								autoComplete="off"
								name="twoFactorCode"
								[placeholder]="'xxxxxx'"
								[maxlength]="6"
								[(ngModel)]="resetModel.twoFactorCode">
						</div>
					</div>

					<button class="ui fluid large blue button"
						[class.disabled]="loginInvalid"
						type="submit">
						Change Password
					</button>
					}
					}
				</div>

				<div class="field" app-field *ngIf="errors.length" [errors]="errors"></div>
			</form>
		</div>
	</div>
</div>