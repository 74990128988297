import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService, AuthService } from '@nstep-common/core';
import { Enviroment, MetricsModel, SupportedEntryTypes } from '@nstep-common/utils';
import moment from 'moment';
import { AuthHelperService } from 'projects/common/core/services/auth/auth-helper.service';
import { Subscription } from 'rxjs';

@Component({
	template: ''
})
export class BaseComponent implements OnDestroy, OnInit, AfterViewInit {

	protected subscriptions: Subscription[] = [];
	private metricsEntries: MetricsModel[] = [];

	ngOnInit(): void {
		const name = (this as any).constructor.name;

		performance.mark(`custom-mark-${name}-start`);
	}

	ngAfterViewInit(): void {
		const name = (this as any).constructor.name;

		if (performance.getEntriesByName(`custom-mark-${name}-start`).length) {
			performance.mark(`custom-mark-${name}-end`);
			performance.measure(`custom-measure-${name}-duration`, `custom-mark-${name}-start`, `custom-mark-${name}-end`);
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((item) => item.unsubscribe());
	}

	hasKeys(value: any): boolean {
		if (!value) {
			return false;
		}

		return Array.isArray(value) ? value.length > 0 : Object.keys(value).length > 0;
	}

	protected registerPerformanceObserver(apiService: ApiService, authHelperService: AuthHelperService, environment: Enviroment): void {
		const observer = new PerformanceObserver((list) => {
			list.getEntries()
				.filter(e => e.entryType !== SupportedEntryTypes.Resource || !e.name.includes('api/metrics'))
				.filter(e => e.entryType !== SupportedEntryTypes.Mark || e.name.startsWith('custom-mark'))
				.filter(e => e.entryType !== SupportedEntryTypes.Measure || e.name.startsWith('custom-measure'))
				.forEach(e => {

					const currentEntry = new MetricsModel({
						application: environment.appName,
						metricName: this.getMetricName(e.entryType),
						resourceMeasured: this.getResourceMeasured(e),
						elapsedTime: this.millisecondsToTimeSpan(e.duration)
					});

					if (e.duration >= 10) {
						this.metricsEntries.push(currentEntry);
					}
				});
		});

		const options = { entryTypes: [SupportedEntryTypes.Measure, SupportedEntryTypes.Resource] };
		observer.observe(options);

		setInterval(() => {
			if (authHelperService.getAccessToken() && this.metricsEntries.length) {
				apiService.postNoContent('metrics', this.metricsEntries).subscribe({
					next: () => {
						this.metricsEntries = [];
					}
				});
			}
		}, environment.metricsCallInterval);
	}

	private millisecondsToTimeSpan(milliseconds: number) {
		// Use moment.duration to parse the duration
		const duration = moment.duration(milliseconds);

		// Extract days, hours, minutes, seconds, and milliseconds
		const days = Math.floor(duration.asDays());
		const hours = duration.hours();
		const minutes = duration.minutes();
		const seconds = duration.seconds();
		const millisecondsRemaining = duration.milliseconds().toFixed();

		// Format into TimeSpan string
		return `${days}.${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${millisecondsRemaining.toString().padStart(3, '0')}`;
	}

	private getMetricName(entryType: string): string {
		switch (entryType) {
			case SupportedEntryTypes.Measure:
				return 'fe_component_init_duration';
			case SupportedEntryTypes.Resource:
				return 'fe_resource_load_duration';
			default:
				return '';
		}
	}

	private getResourceMeasured(entry: PerformanceEntry): string {
		switch (entry.entryType) {
			case SupportedEntryTypes.Measure:
				return entry.name.split('-')[2];
			case SupportedEntryTypes.Resource:
				return entry.name;
			default:
				return '';
		}
	}
}
