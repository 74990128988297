import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent, TableColumn } from '@nstep-common/core';
import { DropdownOption } from '@nstep-common/semantic-ui';
import { createProxy, toast } from '@nstep-common/utils';
import {
	AllowedDto,
	BulkTransactionTypeHistoryItemModel,
	EntitlementPickModel,
	EntitlementPickModelValidator,
	ExtendedPOSLocationDto,
	OperatorBulkTransactionModel,
	AddBalanceActionModel,
	BalanceOverviewModel
} from '@nstep-public/pages';
import { OnlineTransactionService } from '@nstep-public/shared';
import { ValidationErrors } from 'fluentvalidation-ts';
import { chain, flatten } from 'lodash';

@Component({
	selector: 'app-rationed-types-pick',
	templateUrl: './rationed-types-pick.component.html',
})
export class RationedTypesPickComponent extends BaseComponent implements OnInit {

	@Input() rationedItems: AllowedDto[] = [];
	@Input() selectedRationedItem!: AllowedDto;
	@Input() currentPosLocationInfo!: ExtendedPOSLocationDto;
	@Input() balanceConfirmationItem: BalanceOverviewModel | null = null;

	@Output() selectedRationedItemChange = new EventEmitter<AllowedDto>();
	@Output() action = new EventEmitter<AddBalanceActionModel>();

	tableData: any[] = [];
	tableDataReady = true;
	rationedItemsBalance: any;

	entitlementTypeDropdownValues: DropdownOption[] = [];
	selectedEntitlementTypeId!: number;

	tableColumns: TableColumn[] = [
		{ name: 'Data', key: 'date', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Transaction Amount', key: 'transactionAmount', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Operator', key: 'operator', isCellCentered: true, isHeaderCentered: true },
		{ name: 'POS', key: 'posLocation', isCellCentered: true, isHeaderCentered: true }
	];

	isValid = false;

	entitlementPickModel: EntitlementPickModel = createProxy(new EntitlementPickModel(), {
		set: () => this.validate(this.entitlementPickModel)
	});

	validation: ValidationErrors<EntitlementPickModel> = {};

	validate(value: EntitlementPickModel): void {
		const validator = new EntitlementPickModelValidator();
		this.validation = validator.validate(value);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	constructor(private onlineTransactionService: OnlineTransactionService) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.entitlementTypeDropdownValues = chain(this.rationedItems)
			.map(e => new DropdownOption({
				value: e.entitlementTypeId,
				name: e.entitlementType
			}))
			.value();

		this.selectedEntitlementTypeId = this.selectedRationedItem.entitlementTypeId;

		this.entitlementPickModel.unitCount = this.balanceConfirmationItem ? this.balanceConfirmationItem.unitCount : this.selectedRationedItem.minimumBuyAmount;

		this.tableDataReady = false;

		const model = new OperatorBulkTransactionModel({
			amisCardId: this.selectedRationedItem.amisCardId,
			locationId: this.currentPosLocationInfo.location.id,
			headquarterId: this.selectedRationedItem.headquarterId,
			entitlementCodeId: this.selectedRationedItem.entitlementCodeId,
		});

		this.subscriptions.push(
			this.onlineTransactionService.getBulkTransactions(model).subscribe({
				next: (response) => {
					this.rationedItemsBalance = response;

					this.populateTable();

					this.tableDataReady = true;
				},
				error: (response: any) => {
					const errors: string[] = flatten(Object.values(response.error.errors));

					errors.forEach(error => {
						toast('', error, 'red');
					});

					this.tableDataReady = true;
				}
			})
		);
	}

	onEntitlementTypeValueChange() {
		this.tableDataReady = false;

		const currentItem = this.rationedItems.find(e => e.entitlementTypeId === this.selectedEntitlementTypeId)!;
		this.selectedRationedItem = currentItem;
		this.selectedRationedItemChange.emit(this.selectedRationedItem);

		this.entitlementPickModel.unitCount = currentItem.minimumBuyAmount;

		this.populateTable();
		this.tableDataReady = true;
	}

	next(): void {

		const model: BalanceOverviewModel = {
			entitlementType: this.selectedRationedItem.entitlementType,
			unitCount: this.entitlementPickModel.unitCount,
			newBalance: Number(this.selectedRationedItem.balance) + this.entitlementPickModel.unitCount,
			measurementUnit: this.selectedRationedItem.measurementUnit,
			measurementUnitDescription: this.selectedRationedItem.measurementUnitDescription
		};

		this.action.emit({ action: 'next', currentTabIndex: 1, overviewItem: model });
	}

	private populateTable(): void {

		if (!this.rationedItemsBalance[this.selectedEntitlementTypeId]) {
			this.tableData = [];
			return;
		}

		this.tableData = chain(this.rationedItemsBalance[this.selectedEntitlementTypeId].historyItems)
			.map(e => new BulkTransactionTypeHistoryItemModel({
				date: e.date,
				operator: e.operator,
				posLocation: e.posLocation,
				transactionAmount: e.transactionAmount
			}))
			.value();
	}
}
