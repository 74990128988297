<ng-container *ngIf="!askForCode">
	<ng-template *ngIf="!model.canUseEmail"
		[ngTemplateOutlet]="mobileAuthHeader">
	</ng-template>

	<div class="ui two column grid" *ngIf="model.canUseEmail">
		<div class="row">
			<div class="ui vertical divider">Or</div>

			<div class="column">
				<ng-template [ngTemplateOutlet]="mobileAuthHeader">
				</ng-template>
			</div>

			<div class="column">
				<h4 class="ui center aligned header">
					<div class="content">
						<i class="big envelope outline icon"></i>
						Email Authenticator
						<div class="sub header">
							<div class="ui small toggle checkbox">
								<input type="checkbox" [(ngModel)]="model.emailEnabled" (change)="model.mobileEnabled = false" />

								<label>
									<strong class="ui success text">
										<span class="ui success text" *ngIf="model.emailEnabled">Enabled</span>
										<span class="ui error text" *ngIf="!model.emailEnabled">Disabled</span>
									</strong>
								</label>
							</div>
						</div>
					</div>
				</h4>
			</div>
		</div>
	</div>

	<div *ngIf="model.mobileEnabled || !model.canUseEmail"
		style="text-align: left; margin-top: 20px;">
		<div class="ui attached segment"
			*ngIf="isAuthenticated">
			<p>
				There is an authenticator registered for your NSTEP account, you can find its details below.<br />

				<button class="ui primary tertiary button"
					style="padding: 0 !important;"
					(click)="resetAuthenticator()">
					Click here
				</button> if you want to reset it.
			</p>
		</div>

		<div class="ui attached segment">
			<p>We recommend installing "Google Authenticator" app and following these steps:</p>
		</div>

		<div class="ui attached segment">
			<div class="ui two column stackable grid">
				<div class="row">
					<div class="ui vertical divider">Or</div>

					<div class="column">
						<ol>
							<li>Open "Google Authenticator" app.</li>
							<li>Authenticate in the app if desired or necessary.</li>
							<li>Click the "+" button in the right corner.</li>
							<li>Select "Scan a QR code".</li>
							<li>Point the camera at the QR image below.</li>
						</ol>

						<div style="text-align: center;">
							<qr-code [value]="model.mobile.authenticatorUri"
								size="300"
								errorCorrectionLevel="M" />
						</div>
					</div>
					<div class="column">
						<ol>
							<li>Open "Google Authenticator" app.</li>
							<li>Authenticate in the app if desired or necessary.</li>
							<li>Click the "+" button in the right corner.</li>
							<li>Select "Enter a setup key".</li>
							<li>Enter "Account name", e.g. "NSTEP".</li>
							<li>Select the field "Key" and input the values below.</li>
							<li>Finish by pressing "Add".</li>
						</ol>

						<br />

						<div style="text-align: center;">
							<div class="ui big labels">
								<div class="ui label" *ngFor="let part of model.mobile.sharedKey">
									<strong>{{part}}</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="ui attached segment">
			<p>! After you have finished and clicked the button below, we will request a code to confirm the authenticator.</p>
		</div>
	</div>

	<div *ngIf="model.canUseEmail && model.emailEnabled">
		<div class="ui attached segment"
			style="margin-top: 20px">
			<div class="ui two column centered grid">
				<div class="column">
					<div class="field" app-field [error]="validations | path: 'email'">
						<label>Email Address</label>
						<input type="text" [(ngModel)]="model.email">
					</div>
				</div>
			</div>
		</div>

		<div class="ui attached segment">
			<p>! After you click the button below, we will send an email with the code and request it to confirm the authenticator.</p>
		</div>
	</div>

	<button class="ui large blue button"
		*ngIf="!model.canUseEmail || model.emailEnabled || model.mobileEnabled"
		(click)="continue()"
		style="margin-top: 20px">
		Continue
	</button>
</ng-container>

<div class="field" *ngIf="askForCode">
	<label>
		Please enter code from {{model.emailEnabled ? 'email' : 'mobile'}} authenticator:<br />
	</label>

	<div class="ui left icon input">
		<i class="shield alternate icon"></i>
		<input type="text" [(ngModel)]="code"
			autoComplete="off"
			name="code"
			[placeholder]="'xxxxxx'"
			[maxlength]="6">
	</div>


	<br /><br />

	<button class="ui large blue button"
		(click)="enableAuthenticator()">
		Enable
	</button>
</div>

<app-error-message [errors]="errors"></app-error-message>

<ng-template #mobileAuthHeader>
	<h4 class="ui center aligned header">
		<div class="content">
			<i class="alternate big mobile icon"></i>
			Mobile Authenticator
			<div class="sub header"
				*ngIf="model.canUseEmail">
				<div class="ui small toggle checkbox">
					<input type="checkbox" [(ngModel)]="model.mobileEnabled" (change)="model.emailEnabled= false" />
					<label>
						<strong class="ui success text">
							<span class="ui success text" *ngIf="model.mobileEnabled">Enabled</span>
							<span class="ui error text" *ngIf="!model.mobileEnabled">Disabled</span>
						</strong>
					</label>
				</div>
			</div>
		</div>
	</h4>
</ng-template>