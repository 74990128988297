import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { catchError, EMPTY, finalize, tap } from 'rxjs';
import { ValidationErrors } from 'fluentvalidation-ts';

import { createProxy } from "@nstep-common/utils";
import { AccountModel, AccountService, AuthenticationModel, AuthService, BaseComponent, EnableAuthenticatorModel, JwtTokenModel, PasswordSettingsModel, ResetPasswordModel, ResetPasswordModelValidator, SettingsService } from "@nstep-common/core";
import { flatten } from 'lodash';

@Component({
	selector: 'app-authentication',
	templateUrl: './authentication.component.html',
	styleUrls: ['./authentication.component.css']
})
export class AuthenticationComponent extends BaseComponent implements OnInit {
	@Input() userNamePlaceHolder: string = '';
	@Input() passwordPlaceHolder: string = '';
	@Input() userNameMaxLength: number | null = null;
	@Input() passwordMaxLength: number | null = null;

	@Input() hideEmailAuthenticator = true;

	@Input() onValidation: ((model: AuthenticationModel) => ValidationErrors<AuthenticationModel>) | null = null;
	@Output() onLoginCompleted = new EventEmitter<JwtTokenModel>();

	requiresPasswordReset = false;
	requiresTwoFactorActivation = false;
	requiresTwoFactorActivationDays = 0;
	requiresTwoFactorCode = false;

	loading = false;
	showTwoFactor = false;

	loginValidation: ValidationErrors<AuthenticationModel> = {};
	loginInvalid = false;
	loginModel = createProxy(new AuthenticationModel(), {
		set: () => {
			this.errors = [];

			this.resetModel.userName = this.loginModel.userName;
			this.resetModel.oldPassword = this.loginModel.password;

			if (!this.onValidation) {
				return;
			}

			this.loginValidation = this.onValidation(this.loginModel);
			this.loginInvalid = this.hasKeys(this.loginValidation);
		}
	});

	passwordSettings: PasswordSettingsModel = new PasswordSettingsModel();
	resetValidation: ValidationErrors<ResetPasswordModel> = {};
	resetInvalid = false;
	resetModel: ResetPasswordModel = createProxy(new ResetPasswordModel(), {
		set: () => {
			if (!this.requiresPasswordReset) {
				return;
			}

			this.errors = [];

			const validator = new ResetPasswordModelValidator(this.passwordSettings);
			this.resetValidation = validator.validate(this.resetModel);
			this.resetInvalid = this.hasKeys(this.resetValidation);
		}
	});

	errors: string[] = [];

	account: AccountModel | null = null;

	constructor(private accountService: AccountService,
		private authService: AuthService,
		private settingsService: SettingsService) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.logIn();
	}

	onSubmit() {
		if (!this.requiresPasswordReset ? this.loginInvalid : this.resetInvalid) {
			return;
		}

		this.loading = true;

		let observable$ = this.accountService.logIn(this.loginModel);

		if (this.requiresPasswordReset) {
			observable$ = this.accountService.passwordReset(this.resetModel);
		}

		if (this.requiresTwoFactorActivation && this.loginModel.twoFactorCode) {
			observable$ = this.account?.authenticatorStatus?.mobileEnabled || !this.account?.authenticatorStatus?.canUseEmail
				? this.accountService.loginEnableMobileAuthenticator(this.loginModel)
				: this.accountService.loginEnableEmailAuthenticator(this.loginModel, this.account?.authenticatorStatus?.email ?? '');
		}

		const submit = observable$
			.pipe(
				tap(account => {
					this.errors = [];

					if (!this.requiresPasswordReset && account.requiresPasswordReset) {
						this.subscriptions.push(this.settingsService.getPasswordSettings()
							.subscribe(settings => this.passwordSettings = settings));

						this.requiresPasswordReset = true;
					}
					else if (!this.requiresTwoFactorActivation && account.requiresTwoFactorActivation) {
						this.requiresTwoFactorActivation = true;
						this.requiresTwoFactorActivationDays = account.requiresTwoFactorActivationDays;
					}

					this.requiresTwoFactorCode = account.requiresTwoFactor;
					this.account = account;

					if (!account.requiresTwoFactorActivation) {
						this.logIn();
					}
				}),
				catchError((resp: any) => {
					const errors = flatten(Object.values(resp)) as string[];
					this.errors = errors;

					return EMPTY;
				}),
				finalize(() => {
					this.loading = false;
				})
			)
			.subscribe()

		this.subscriptions.push(submit);
	}

	logIn(): any {
		if (this.authService.JWT) {
			this.onLoginCompleted.emit(this.authService.JWT);
			return;
		}

		if (!this.account) {
			return;
		}

		const jwt = this.authService.logIn(this.account);

		if (!jwt) {
			return;
		}

		this.onLoginCompleted.emit(jwt);
	}

	sendEmailCode(email: string): any {
		const submit = this.accountService.sendEmailAuthenticatorCode(this.loginModel, email)
			.pipe(
				tap(() => {
					this.errors = [];
				}),
				catchError((resp: any) => {
					const errors = flatten(Object.values(resp)) as string[];
					this.errors = errors;

					return EMPTY;
				}),
				finalize(() => {
					this.loading = false;
				})
			)
			.subscribe()

		this.subscriptions.push(submit);
	}
}
