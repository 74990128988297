import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { JsonMapper } from "@nstep-common/utils";
import { JwtTokenModel } from "projects/common/core/models/jwt-token.model";

// This helper allows abstracting the local storage and prevents circular service dependencies for simple session checks
@Injectable({
	providedIn: 'root'
})
export class AuthHelperService {
	constructor(private jwtHelper: JwtHelperService) {
	}

	decodeJwt(accesToken: string | null = null): JwtTokenModel | null {
		accesToken = accesToken ?? this.getAccessToken();

		if (!accesToken) {
			return null;
		}

		const jwt = this.jwtHelper.decodeToken(accesToken);

		const jsonMapper = new JsonMapper();
		const mapResult = jsonMapper.deserializeObject(jwt, JwtTokenModel);

		if (Object.keys(mapResult.errors).length) {
			return null;
		}

		return mapResult.value;
	}

	getAccessToken(): string | null {
		return localStorage.getItem('accessToken') || '';
	}

	isAccessTokenExpired(): boolean {
		return this.jwtHelper.isTokenExpired(this.getAccessToken());
	}

	getRefreshToken(): string {
		return localStorage.getItem('refreshToken') || '';
	}

	storeSecurityTokens(refreshToken: string, accessToken: string): void {
		localStorage.setItem('refreshToken', refreshToken);
		localStorage.setItem('accessToken', accessToken);
	}

	clearSecurityTokens() {
		localStorage.clear();
	}
}