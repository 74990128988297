export class MetricsModel {
	application!: string;
	metricName!: string;
	resourceMeasured!: string;
	elapsedTime!: string;

	constructor(data: Partial<MetricsModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export enum SupportedEntryTypes {
	Element = 'element',
	Event = 'event',
	FirstInput = 'first-input',
	LargestContentfulPaint = 'largest-contentful-paint',
	LayoutShift = 'layout-shift',
	LongAnimationFrame = 'long-animation-frame',
	Longtask = 'longtask',
	Mark = 'mark',
	Measure = 'measure',
	Navigation = 'navigation',
	Paint = 'paint',
	Resource = 'resource',
	VisibilityState = 'visibility-state'
}