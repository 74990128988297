import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationErrors } from 'fluentvalidation-ts';
import { EMPTY, catchError, finalize, tap } from 'rxjs';

import { AuthenticationModel, BaseComponent, JwtTokenModel } from '@nstep-common/core';
import { ClientVerifyModel, LoginModelValidator, LoginOperatorModelValidator } from '@nstep-public/pages';
import { ClientsService } from '@nstep-public/shared';
import { ModalComponent } from '@nstep-common/semantic-ui';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html'
})
export class LoginComponent extends BaseComponent implements OnInit {
	@ViewChild('clientConsentModal') clientConsentModal!: ModalComponent;
	verifiedClient: ClientVerifyModel = new ClientVerifyModel();

	loading = false;
	isCardLogin = true;
	isAuthenticated = false;

	constructor(private clientService: ClientsService,
		private router: Router,
		private route: ActivatedRoute) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.isCardLogin = !this.router.url.endsWith('local');
	}

	validateLogin(model: AuthenticationModel): ValidationErrors<AuthenticationModel> {
		const validator = this.isCardLogin ? new LoginModelValidator() : new LoginOperatorModelValidator();
		return validator.validate(model);
	}

	afterLogin(jwt: JwtTokenModel) {
		if (jwt.role == 'Operator') {
			this.router.navigate(['select-location']);
		}
		else if (!this.verifyClient()) {
			this.router.navigate(['']);
		}
	}

	verifyClient(): boolean {
		const clientId = this.route.snapshot.queryParamMap.get('clientId') || null;
		if (!clientId) {
			return false;
		}

		this.loading = true;

		const verifyClient = this.clientService.verifyClient(clientId)
			.pipe(
				tap(resp => {
					if (resp) {
						this.verifiedClient = resp;
						this.clientConsentModal.toggle();
					}
					else {
						this.router.navigate(['']);
					}
				}),
				catchError(() => {
					this.router.navigate(['']);
					return EMPTY;
				}),
				finalize(() => this.loading = false)
			)
			.subscribe();

		this.subscriptions.push(verifyClient);

		return true;
	}

	consentClient(yes: boolean): void {
		if (!yes) {
			window.location.href = this.verifiedClient.loginUrl;
			return;
		}

		this.loading = true;

		const authorizeClient = this.clientService.authorizeClient(this.verifiedClient.id)
			.pipe(
				tap(resp => {
					window.location.href = resp.redirectUrl;
				}),
				catchError(() => {
					this.router.navigate(['']);
					return EMPTY;
				}),
				finalize(() => this.loading = false)
			)
			.subscribe();

		this.subscriptions.push(authorizeClient);
	}
}
