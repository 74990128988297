import { JsonProperty } from "@nstep-common/utils";


/** REQUESTS */

export class AuthenticationModel {
	userName: string = '';
	password: string = '';
	twoFactorCode: string | null = null;

	constructor(data: Partial<AuthenticationModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class ResetPasswordModel {
	userName!: string;
	oldPassword!: string;
	newPassword!: string;
	confirmPassword!: string;
	twoFactorCode: string | null = null;

	constructor(data: Partial<ResetPasswordModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

/** RESPONSES */

export class MobileAuthenticatorModel {
	@JsonProperty({ type: String, array: true })
	sharedKey!: string[];

	@JsonProperty({ type: String })
	authenticatorUri!: string;

	constructor(data: Partial<MobileAuthenticatorModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class AuthenticatorStatus {
	@JsonProperty({ type: Boolean })
	mobileEnabled!: boolean;

	@JsonProperty({ type: MobileAuthenticatorModel })
	mobile!: MobileAuthenticatorModel;

	@JsonProperty({ type: Boolean })
	canUseEmail!: boolean;

	@JsonProperty({ type: Boolean })
	emailEnabled!: boolean;

	@JsonProperty({ type: String, nullable: true })
	email!: string | null;
	
	constructor(data: Partial<AuthenticatorStatus> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class AccountModel {
	@JsonProperty({ type: String, nullable: true })
	accessToken!: string | null;

	@JsonProperty({ type: String, nullable: true })
	refreshToken!: string | null;

	@JsonProperty({ type: Boolean })
	requiresPasswordReset!: boolean;

	@JsonProperty({ type: Boolean })
	requiresTwoFactor!: boolean;

	@JsonProperty({ type: Boolean })
	requiresTwoFactorActivation!: boolean;

	@JsonProperty({ type: Number })
	requiresTwoFactorActivationDays!: number;

	@JsonProperty({ type: AuthenticatorStatus, nullable: true })
	authenticatorStatus!: AuthenticatorStatus | null;

	constructor(data: Partial<AccountModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class RefreshTokensModel {
	@JsonProperty({ type: String })
	accessToken!: string;

	@JsonProperty({ type: String })
	refreshToken!: string;

	constructor(data: Partial<RefreshTokensModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class EnableAuthenticatorModel {
	@JsonProperty({ type: String })
	code!: string;

	constructor(data: Partial<EnableAuthenticatorModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}