import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { AuthHelperService } from "@nstep-common/core";
import { AppSource, Enviroment, LogEntry, LogLevel, LogModel, LogPublisher } from "@nstep-common/utils";

import { Observable, of } from "rxjs";

export class LogWebApi extends LogPublisher {
	private apiUrl: string;

	constructor(private httpClient: HttpClient,
		private authHelperService: AuthHelperService,
		private environment: Enviroment) {
		super();

		let url = this.environment.apiUrl;

		if (!url.startsWith('http')) {
			const hostUrl = window.location.protocol + '//' + window.location.host;
			url = `${hostUrl}${this.environment.apiUrl}`;
		}

		this.apiUrl = url;
	}

	log(record: LogEntry): Observable<any> {
		// We do this to prevent requests when the server is disconnected
		if (record.extraInfo.length) {
			const resp = record.extraInfo[0] as HttpErrorResponse;
			if (resp instanceof HttpErrorResponse && resp.status === 0) {
				return of();
			}
		}

		// We do this to prevent requests when user is not logged in
		if (!this.authHelperService.decodeJwt()) {
			return of();
		}

		const model = new LogModel();

		model.methodName = this.getCaller(5);
		model.parameters = record.buildLogString();
		model.deviceIdentifier = record.source == AppSource.Public ? 'NSTEP Public Web App' : 'NSTEP Internal Web App';
		model.timeStamp = record.entryDate;
		model.isError = record.level === LogLevel.Error;

		return this.httpClient.post<LogModel>(`${this.apiUrl}/account/post-logs`, model);
	}
}